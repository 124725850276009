import React from 'react';

import Layout from "../../components/layout_en"
import Seo from "../../components/seo"
import {Container} from "react-bootstrap"
import {graphql} from 'gatsby'
import Markdown from "react-markdown"
import tree from "../../images/tree.svg"
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'



const API_URL = process.env.API_URL;

const Services = ({data}) => {

return (
  <Layout>
     <Seo  
      titleTemplate={`Termes of services | Trezorino`}
      title="Termes of services"
      description="Termes of services"
      image={tree}
      lang="en"
      />

    <div className="header-box-tarifs">
      

      <Container className="pt-5" style={{paddingBottom:"20%"}} >

      <div className="d-flex align-items-start">
       
          <div className="tab-content col-8" id="v-pills-tabContent" style={{Height:"100%"}}>

        
          {data.allStrapiLegales.edges.map(document => (
            <div  key={document.node.Identity}>
              <Markdown children={document.node.Content} skipHtml={false} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} transformImageUri={uri => uri.startsWith('http') ? uri : `${API_URL}${uri}`}/>
            </div>
            ))}

          </div>
        </div>
    
    
    </Container>



    </div>
   
  </Layout>
)
}

export default Services

export const data = graphql`  
query service_en {
  allStrapiLegales(filter: {Id_content: {eq: "conditions"}}) {
    edges {
      node {
        Content
        Id_content
      }
    }
  }
}
`
